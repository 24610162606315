<template>
  <div class="kanbanBoard">
    <div v-for="tasks in data" :key="tasks.status" class="kanbanBoard__col">
      <div class="kanbanBoard__col-title">
        {{ tasks.status }}
        <span style="margin-left: 5px">{{ tasks.data.length }}</span>
      </div>
      <div class="kanbanBoard__col__items">
        <div
          v-if="tasks.loading"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
          "
        >
          <CSpinner color="primary" />
        </div>
        <div
          v-else-if="!tasks.data.length"
          style="text-align: center; margin-top: 5px"
        >
          Tasks not found
        </div>
        <div
          v-for="task in tasks.data"
          :key="`${tasks.status}-${task.task_id}`"
          class="kanbanBoard__col__item"
        >
          <div class="kanbanBoard__col__item__row">
            <div class="kanbanBoard__col__item__row-title">Name</div>
            <span
              class="kanbanBoard__col__item__row-value tdul"
              style="cursor: pointer"
              @click="goToView(task)"
              >{{ task.task_name }}</span
            >
          </div>
          <div class="kanbanBoard__col__item__row">
            <div class="kanbanBoard__col__item__row-title">Project</div>
            <router-link
              :to="{ name: 'Project', params: { id: task.project_id } }"
              class="kanbanBoard__col__item__row-value tdul"
              >{{ task.project_name }}</router-link
            >
          </div>
          <div class="kanbanBoard__col__item__row">
            <div class="kanbanBoard__col__item__row-title">Due</div>
            <div class="kanbanBoard__col__item__row-value">
              {{ $BtoF({ value: task.task_due_date }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: [
        { status: 'Pending Scope Approval', data: [] },
        { status: 'In Review', data: [] },
        { status: 'Pending Signatures', data: [] },
        { status: 'Overdue', data: [] },
      ],
    },
  },
  methods: {
    goToView(item) {
      if (item.task_type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: item.project_id, task_id: item.task_id },
        })
      } else if (item.task_type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: item.project_id, task_id: item.task_id },
        })
      } else if (item.task_type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: item.project_id, task_id: item.task_id },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.kanbanBoard {
  display: flex;
  overflow: auto;
  margin-left: -10px;
  margin-right: -10px;

  &__col {
    width: 100%;
    margin: 10px;
    min-width: 246px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &__item {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #b1b7c1;
      border-radius: 6px;

      &s {
        min-height: 500px;
        max-height: 500px;
        overflow: auto;
      }

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        color: #303c54;

        &-title {
          color: #303c54;
          margin-right: 5px;
        }

        &-value {
          font-weight: 500;
          text-align: right;
          color: #303c54;
          overflow: hidden;
          max-width: 100%;

          &:hover {
            color: #303c54;
          }

          &.tdul {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
