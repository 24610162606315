import {ref} from 'vue'
import ApiService from '@/services/ApiService'

function setLoading(data = [], loading = true) {
    data.forEach((el, index) => (data[index]['loading'] = loading))

    return data
}

const useTaskKanban = () => {
    const tasks = ref([
        {status: 'In Review', data: []},
        {status: 'Pending Signatures', data: []},
        {status: 'Closed', data: []},
        {status: 'Overdue', data: []},
    ])

    async function fetchData(f = {}, typeColumn = {}) {
        setLoading(tasks.value, true)
        return await ApiService.api.tasks.getTasksKanban({
            params: {
                search: f.search || '',
                due_in: f.due_in ? f.due_in : 'week',
                priority: f.priority,
                status: f.status,
                [typeColumn.name]: typeColumn.value,
            },
        })
            .then((res) => {
                tasks.value = [
                    {status: 'In Review', data: res.data.data.in_review},
                    {status: 'Pending Signatures', data: res.data.data.pending_signature},
                    {status: 'Closed', data: res.data.data.closed},
                    {status: 'Overdue', data: res.data.data.overdue},
                ]
            })
            .finally(() => setLoading(tasks.value, false))
    }

    return {
        tasks,
        fetchData,
    }
}
export default useTaskKanban
